<div class="main-order-review">
  <ng-container *ngIf="revisionRequested">
    <div class="breadcrumbs" *ngIf="!token">
      <a routerLink="/"><img src="../../../assets/images/home-2.svg" alt="" /></a> /
      <a routerLink="../..">Orders</a> /
      <p>Order View [{{ orderNo }}]</p>
      /
      <p>Request Revision</p>
    </div>

    <div class="main-order-review__row" [ngClass]="{ 'row-reverse': isRightSidePreference, 'revision-wrapper': revisionRequested }" *ngIf="revisionRequested">
      <div class="left-pane-wrapper">
        <div class="main-order-review__left header-left">
          <button class="button-outline btn-wrap" type="button" (click)="goBackToFindingOrReview()">
            <app-svg [svgPath]="'../../../../assets/images/arrow-left.svg'"></app-svg>Go back to {{stepForRevision === 0 ? 'Order Review' : 'Review Findings' }}</button>
          <mat-form-field subscriptSizing="dynamic">
            <mat-select [value]="revisionList[0]" [(ngModel)]="selectedRevision">
              <mat-option *ngFor="let revision of revisionList" [value]="revision">{{revision.name}}</mat-option>
            </mat-select>
            <mat-icon matSuffix>expand_more</mat-icon>
          </mat-form-field>
        </div>
        <div class="main-order-review__right header-right">
          <div class="main-order-review__action-dropdown" (click)="expendOrderInfo()">
            <app-svg [svgPath]="'../../../../assets/images/info.svg'"></app-svg>
          </div>
          <div class="switch-wrapper">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="revisionSendToClient" [disabled]="stepForRevision === 1">
              <span class="slider round"></span>
            </label>
            <small>Send Revision to Client</small>
          </div>
          <button class="btn-primary" type="button" (click)="sendRevision(0)" *ngIf="stepForRevision === 0">{{revisionSendToClient ? 'Next' : 'Send Revision'}}</button>
          <button class="btn-primary" type="button" (click)="sendRevision(1)" *ngIf="stepForRevision === 1">Send Revision</button>
        </div>
      </div>
      <div class="right-pane-wrapper">
        <div class="main-order-review__left revision-Left" *ngIf="stepForRevision === 0">
          <div class="revision-left-header">
            <h5>Review Rejected Findings</h5>
            <p><strong>Count:</strong>{{ rejectedfindings && rejectedfindings === 1 ? rejectedfindings + ' record' : rejectedfindings + ' records' }}</p>
          </div>
          <app-order-findings-view
            [findingsData]="findingsData"
            [resolvedFindings]="resolvedfindings"
            [reviewId]="reviewId"
            [orderStatus]="orderStatus"
            [rejectedfindings]="rejectedfindings"
            (findingResolved)="updateResolvedfindingsCount($event)"
            (updateFindingsList)="getOrderDetails($event.reloadDoc, $event.isActionPerformed)"
            [toggleState]="toggleState"
            [isPreview]="isPreview"
            [totalFindings]="totalfindings"
            [originalFindingsData]="originalFindingsData"
            [findingsTabSelection]="findingsTabSelection"
            (applyFilterOnFindings)="getFilteredFindings(findingsTabSelection, $event)"
            [isActionPerformed]="isActionPerformed"
            [xpathToScroll]="xpathToScroll"
            [enableFindingClick]="enableFindingClick"
            [revisionRequested]="revisionRequested"
            (backToReport)="togglePdfView()"
            (draggedFinding)="draggedFindingPlace($event)"
            [vendorAndReviewer]="vendorAndReviewer"
            [sortRevisionArray]="sortRevisionArray"
            (updateSortedList)="updateSortedList($event)"
          ></app-order-findings-view>
        </div>
        <div class="main-order-review__right" [ngClass]="{'revision-right': revisionRequested}">
          <app-pdf-view
            [expendInfo]="expendInfo"
            [orderReviewHeaderData]="orderReviewHeaderData"
            [verifiedAddress]="addressVerified"
            [clientDetail]="clientDetail"
            [stepForRevision]="stepForRevision"
          ></app-pdf-view>

          <app-revision-request
            [originalFindingsData]="originalFindingsData"
            [sortRevisionArray]="sortRevisionArray"
            [draggedFindings]="draggedFindings"
            [stepForRevision]="stepForRevision"
            (backToReport)="togglePdfView()"
            (sortedArray)="sortedArray($event)"
          ></app-revision-request>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="main-order-review__row" [ngClass]="{ 'row-reverse': isRightSidePreference }" *ngIf="!revisionRequested">
    <div class="main-order-review__left">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" [selectedIndex]="selectedTab">
        <mat-tab>
          <ng-template mat-tab-label>
            <app-svg [svgPath]="'../../../../assets/images/find-in.svg'"></app-svg>
            <span>Findings</span>
          </ng-template>
          <app-findings-stats-view
            [hidden]="revisionRequested"
            [totalfindings]="totalfindings"
            [resolvedfindings]="resolvedfindings"
            [orderId]="orderId"
            [orderNo]="orderNo"
            [isPreview]="isPreview"
            [activeFilter]="findingsTabSelection"
            [rejectedFindings]="rejectedfindings"
            [orderStatus]="orderStatus"
            [countData]="countData"
            [vendorAndReviewer]="vendorAndReviewer"
            (backToReport)="togglePdfView()"
            (findingsType)="getFilteredFindings($event, filteredFindings)"
            (updateOrderStatus)="getOrderDetails($event.reloadDoc, $event.isActionPerformed)"
          ></app-findings-stats-view>
          <app-order-findings-view
            [findingsData]="findingsData"
            [resolvedFindings]="resolvedfindings"
            [reviewId]="reviewId"
            [orderStatus]="orderStatus"
            [rejectedfindings]="rejectedfindings"
            (findingResolved)="updateResolvedfindingsCount($event)"
            (updateFindingsList)="getOrderDetails($event.reloadDoc, $event.isActionPerformed)"
            [toggleState]="toggleState"
            [isPreview]="isPreview"
            [totalFindings]="totalfindings"
            [originalFindingsData]="originalFindingsData"
            [findingsTabSelection]="findingsTabSelection"
            (applyFilterOnFindings)="getFilteredFindings(findingsTabSelection, $event)"
            [isActionPerformed]="isActionPerformed"
            [xpathToScroll]="xpathToScroll"
            [enableFindingClick]="enableFindingClick"
            [revisionRequested]="revisionRequested"
            (backToReport)="togglePdfView()"
            [vendorAndReviewer]="vendorAndReviewer"
            [sortRevisionArray]="sortRevisionArray"
            (updateSortedList)="updateSortedList($event)"
          ></app-order-findings-view>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <app-svg [svgPath]="'../../../../assets/images/file-document-outline.svg'"></app-svg>
            <span>Documents</span>
          </ng-template>
          <app-order-review-documents
            [documents]="documents"
            [data]="data"
            [isPreview]="isPreview"
          ></app-order-review-documents>
        </mat-tab>
        <mat-tab *ngIf="userConfig.checkPermissions(permission.MESSAGES_VIEW)">
          <ng-template mat-tab-label>
            <app-svg
              [svgPath]="'../../../../assets/images/message-processing-outline.svg'"
            ></app-svg>
            <span>Messages</span>
          </ng-template>
          <app-order-messages
            [orderId]="orderId"
            [orderDetail]="orderDetail"
            [orderHistory]="orderHistory"
            [unAssigned]="isPreview"
          ></app-order-messages>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <app-svg [svgPath]="'../../../../assets/images/repeat.svg'"></app-svg>
            <div>Revisions</div>
          </ng-template>
          <app-revision-messages [orderId]="orderId"></app-revision-messages>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="main-order-review__right">
      <div class="main-order-review__header-wrap">
        <div class="breadcrumbs" *ngIf="!token">
          <a routerLink="/"><img src="../../../assets/images/home-2.svg" alt="" /></a> /
          <a routerLink="../..">Orders</a> /
          <p>Order View [{{ orderNo }}]</p>
          <div class="order-review-header__col">
            <div class="order-review-header__order-number">
              <app-order-status [orderStatus]="orderStatus"></app-order-status>
            </div>
          </div>
        </div>
        <app-order-review-header
          [page]="'edit-order'"
          [orderNo]="orderNo"
          [source]="source"
          [orderStatus]="orderStatus"
          [rejectedfindings]="rejectedfindings"
          [revisionNumber]="revisionNumber"
          [reviewId]="reviewId"
          [resolvedFindings]="resolvedfindings"
          [totalFindings]="totalfindings"
          (orderHeaderUpdated)="getOrderDetails($event.reloadDoc, $event.isActionPerformed)"
          [toggleState]="toggleState"
          (toggleStateChange)="togglePdfDisplayState()"
          [formHtml]="formHtml"
          [isPreview]="isPreview"
          [isEADactive]="orderReviewHeaderData.isEADactive"
          [isUCDPactive]="orderReviewHeaderData.isUCDPactive"
          (orderInfoCollapse)="expendOrderInfo()"
          [currentUserId]="currentUserId"
          [isRightSidePreference]="isRightSidePreference"
          (updateLayoutPreference)="getConfigurationsFromLS()"
          [eadAppraisalNumber]="eadAppraisalNumber"
          [ucdpAppraisalNumber]="ucdpAppraisalNumber"
          [revisionRequested]="revisionRequested"
          (backToReport)="togglePdfView()"
          [isEADsubmitted]="isEADsubmitted"
          [isUCDPsubmitted]="isUCDPsubmitted"
          [vendorAndReviewer]="vendorAndReviewer"
        ></app-order-review-header>
      </div>

      <app-pdf-view
        [expendInfo]="expendInfo"
        [orderReviewHeaderData]="orderReviewHeaderData"
        [verifiedAddress]="addressVerified"
        [clientDetail]="clientDetail"
        [clientOnReportDetail]="clientOnReportDetail"
      ></app-pdf-view>

      <app-sub-forms-view
        *ngIf="!oldOrders"
        [findingForm]="getFindingForm"
        [reviewId]="reviewId"
        (formChange)="onFormChange($event)"
        (filteredForms)="getFilteredForms($event)"
      ></app-sub-forms-view>

      <app-pdf-viewer
        [pdfUrl]="pdfUrl"
        [hidden]="!toggleState"
      ></app-pdf-viewer>

      <div
        id="htmlContainer"
        [ngClass]="{ 'old-order': oldOrders }"
        [hidden]="toggleState"
      >
        <div [innerHTML]="formHtml"></div>
      </div>
    </div>
  </div>
</div>
